<script setup>
import Recorder from 'js-audio-recorder';
import { onMounted, ref, nextTick, onBeforeUnmount } from 'vue';
import { costXfAsrSecond_api } from '@/api/useUnity';
import { debounce, fetchWithCatch, getCurrentDateTime } from '@/utils/useToolSet';
import { useAsrTimeStore } from '@/store/module/useAsrTimeStore';
import { useRouter } from 'vue-router';
import { ElMessage } from 'element-plus';
import { ArrowLeftBold } from '@element-plus/icons-vue';

const router = useRouter();
const isShowIframe = ref(false);
const isShowUnity = ref(true);
const loading = ref(true);
const isdisabled = ref(true);
const voiceStatus = ref(false);
const recorder = ref(null);
const isReaddy = ref(true);
const unityH = ref(0); // 打开数字人按钮高度
const getKeyInner = ref(null); // 定时器id
const getKeyTime = ref(1000); // 定时器轮询间隔
const getKeyNum = ref(100); // 最多轮询次数
const aihuman_id = ref('');
const key = ref('');
const assetUrl = ref('');
const recorderd = ref({});
const recognitionTxt = ref('点击开始聊天吧');
const btnStatus = ref('UNDEFINED');
const resultText = ref('');
const dialogue = ref(false);
const iframeRef = ref(null);
const unityRef = ref(null);
const audioCtx = ref(null);

fetchWithCatch('https://unityapp.10wei.top/unityui/AssetUrl/getAssetUrl').then((res) => {
  const { shuziren } = res.data;
  assetUrl.value = shuziren;
});

const pageW = ref(490);
const pageH = ref(500);
const pageTop = ref(window.innerHeight - pageH.value - 17);
const pageLeft = ref(window.innerWidth - pageW.value - 17);
window.addEventListener('resize', () => {
  debounce(() => {
    pageLeft.value = window.innerWidth - pageW.value - 17;
    pageTop.value = window.innerHeight - pageH.value - 17;
  })();
});

// 拖动位置
const isMove = ref(false);
const isDragging = ref(false);
const dragOffsetX = ref(0);
const dragOffsetY = ref(0);
const startDrag = (e) => {
  isDragging.value = true;
  const rect = e.target.getBoundingClientRect();
  dragOffsetX.value = e.clientX - rect.left;
  dragOffsetY.value = e.clientY - rect.top;
};
const dragging = (event) => {
  if (!isDragging.value) return;
  pageLeft.value = event.clientX - dragOffsetX.value;
  pageTop.value = event.clientY - dragOffsetY.value;

  if (pageLeft.value < 0) {
    pageLeft.value = 0;
  } else if (pageLeft.value > window.innerWidth - pageW.value) {
    pageLeft.value = window.innerWidth - pageW.value;
  }
  if (pageTop.value < 0) {
    pageTop.value = 0;
  } else if (pageTop.value > window.innerHeight - pageH.value) {
    pageTop.value = window.innerHeight - pageH.value;
  }
};
const stopDrag = () => {
  isDragging.value = false;
};
const confirmPosition = () => {
  isMove.value = false;
};

const messageHandle = (e) => {
  let iframe = iframeRef.value;
  if (e.data.type === 'JumpLink') {
    router.push({ path: e.data.data });
  } else if (e.data.type === 'Start') {
    isShowIframe.value = false;
    loading.value = false;
    isdisabled.value = false;
    isShowUnity.value = true;
    if (iframe && iframe.contentWindow) {
      iframe.contentWindow.postMessage(
        {
          data: true,
          type: 'open'
        },
        '*'
      );
    }
  } else if (e.data.type === 'off') {
    isShowIframe.value = !isShowIframe.value;
    isShowUnity.value = !isShowUnity.value;
  } else if (e.data.type === 'startRecord') {
    voiceStatus.value = true;
    window.navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then(() => {
        if (voiceStatus.value) {
          ElMessage.success('开始录音');
          if (iframe && iframe.contentWindow) {
            iframe.contentWindow.postMessage(
              {
                data: true,
                type: 'startRecord'
              },
              '*'
            );
          }
          recorder.value = new Recorder({
            sampleBits: 16,
            sampleRate: 16000,
            numChannels: 1
          });
          recorder.value.start().then(
            () => {},
            (err) => {
              console.log(err);
            }
          );
        }
      })
      .catch((err) => {
        console.log(err);
        if (iframe && iframe.contentWindow) {
          iframe.contentWindow.postMessage(
            {
              info: '开始当前浏览器已禁止开启麦克风录音',
              type: 'recordErr'
            },
            '*'
          );
        }
        ElMessage.error('开始当前浏览器已禁止开启麦克风录音');
        voiceStatus.value = false;
      });
  } else if (e.data.type === 'stopRecord') {
    ElMessage.success('结束录音');
    voiceStatus.value = false;
    if (recorder.value) {
      recorder.value.stop();
      getRecorder();
    }
  } else if (e.data.type === 'changeVoiceStatus') {
    voiceStatus.value = e.data.data;
  } else if (e.data.type === 'ReportReaddy') {
    isReaddy.value = true;
  } else if (e.data.type === 'message') {
    console.log(e.data.data, '测试');
    // ElMessage(e.data.data);
  } else if (e.data.type === 'audioPlayHandle') {
    audioPlayHandle(e.data.data);
  } else if (e.data.type === 'audioPauseHandle') {
    audioPauseHandle();
  } else if (e.data.type === 'dragResize') {
    isMove.value = true;
  } else if (e.data.type === 'recorderd') {
    if (e.data.data === 'start') {
      recorderd.value.start({
        sampleRate: 16000,
        frameSize: 1280
      });
    } else {
      recorderd.value && recorderd.value[e.data.data] && recorderd.value[e.data.data]();
    }
  } else if (e.data.type === 'dialogue') {
    dialogue.value = e.data.data;
  }
};

onMounted(() => {
  nextTick(() => {
    iframeRef.value.contentWindow.focus();
  });

  window.addEventListener('message', messageHandle, false);
  unityH.value = unityRef.value.clientHeight;

  navigator.mediaDevices.getUserMedia({ audio: true });

  let url = `https://unityapp.10wei.top/unityweb/ComDomain/getDomainKey?domain=${location.hostname}`;
  fetchWithCatch(url).then((res) => {
    let data = res.data;
    if (data) {
      key.value = data.key;
      aihuman_id.value = data.aihuman_id;
    }
    if (key.value) {
      initPolling(1);
    }
  });

  const AsrTimeStore = useAsrTimeStore();
  // eslint-disable-next-line no-undef
  recorderd.value = new RecorderManager('./recorder');
  recorderd.value.onStart = () => {
    AsrTimeStore.start_time = getCurrentDateTime();
    changeBtnStatus('OPEN');
  };
  recorderd.value.onFrameRecorded = ({ isLastFrame, frameBuffer }) => {
    if (iframeRef.value && iframeRef.value.contentWindow) {
      iframeRef.value.contentWindow.postMessage(
        {
          data: { data: new Int8Array(frameBuffer), isEnd: isLastFrame },
          type: 'onFrameRecorded'
        },
        '*'
      );
    }
  };
  recorderd.value.onStop = async () => {
    if (AsrTimeStore.start_time) {
      const { code, info } = await costXfAsrSecond_api({
        key: key.value,
        start_time: AsrTimeStore.start_time,
        end_time: getCurrentDateTime()
      });
      if (code === 0) {
        ElMessage.error(info || '获取key失败');
      }
      AsrTimeStore.start_time = '';
    }
    dialogue.value = false;
    changeBtnStatus('CLOSED');
  };
});

onBeforeUnmount(() => {
  window.removeEventListener('resize', messageHandle);
  if (getKeyInner.value) {
    clearTimeout(getKeyInner.value);
    getKeyInner.value = null;
  }
});

const audioPlayHandle = (audioUrl) => {
  if (audioUrl) {
    fetch(audioUrl)
      .then((res) => res.arrayBuffer())
      .then((res) => {
        if (audioCtx.value) {
          audioCtx.value.suspend();
          audioCtx.value.close();
        }
        const ctx = new window.AudioContext() || new window.webkitAudioContext();
        ctx.decodeAudioData(
          res,
          (buffer) => {
            // 创建AudioBufferSourceNode对象
            let source = ctx.createBufferSource();
            source.buffer = buffer;
            source.connect(ctx.destination);
            // 指定位置开始播放
            source.start(0);
          },
          (e) => {
            console.info('处理出错 e', e);
          }
        );
        audioCtx.value = ctx;
      });
  }
};
const audioPauseHandle = () => {
  if (audioCtx.value) {
    audioCtx.value.suspend();
    audioCtx.value.close();
    audioCtx.value = null;
  }
};
const initPolling = (num) => {
  let iframe = iframeRef.value;
  if ((iframe && iframe.contentWindow) || num >= getKeyNum.value) {
    if (iframe && iframe.contentWindow) {
      // noinspection JSUnusedGlobalSymbols
      iframe.onload = () => {
        iframe.contentWindow.postMessage(
          {
            data: key.value,
            type: 'key'
          },
          '*'
        );
      };
    }
    if (getKeyInner.value) {
      clearTimeout(getKeyInner);
      getKeyInner.value = null;
    }
  } else {
    getKeyInner.value = setTimeout(() => {
      initPolling(num + 1);
    }, getKeyTime.value);
  }
};
const getRecorder = () => {
  // 录音结束，获取取录音数据
  let wavBlob = recorder.value.getWAVBlob(); // 获取 WAV 数据
  // 使用该插件只能导出wav格式的音频，然后把wav格式的音频转化为文件格式，方便使用接口的形式传给后端。
  let file = new File([wavBlob], 'audio.wav', {
    type: 'wav',
    lastModified: Date.now()
  });
  let iframe = iframeRef.value;
  if (iframe && iframe.contentWindow) {
    iframe.contentWindow.postMessage(
      {
        data: file,
        type: 'stopRecord'
      },
      '*'
    );
  }
};
const openIframe = () => {
  let iframe = iframeRef.value;
  if (!isMove.value) {
    isShowIframe.value = !isShowIframe.value;
    isShowUnity.value = !isShowUnity.value;
    if (!loading.value) {
      iframe.contentWindow.postMessage(
        {
          data: true,
          type: 'open'
        },
        '*'
      );
      iframe.contentWindow.focus();
    } else {
      iframe.contentWindow.postMessage(
        {
          data: true,
          type: 'show'
        },
        '*'
      );
      iframe.contentWindow.focus();
    }
  }
};
const changeBtnStatus = (status) => {
  let iframe = iframeRef.value;
  btnStatus.value = status;
  if (status === 'CONNECTING') {
    recognitionTxt.value = '加载中...';
    resultText.value = '';
  } else if (status === 'OPEN') {
    recognitionTxt.value = '请说，我正在听';
  } else if (status === 'CLOSING') {
    recognitionTxt.value = '抱歉，我没听清你说什么';
  } else if (status === 'CLOSED') {
    recognitionTxt.value = '点击开始聊天吧';
  }
  if (iframe && iframe.contentWindow) {
    iframe.contentWindow.postMessage(
      {
        data: status,
        type: 'changeBtnStatus'
      },
      '*'
    );
  }
};
</script>

<template>
  <div
    :class="{ drag_box: true, isMove: isMove, pointer: !isShowUnity }"
    :style="{ top: pageTop + 'px', left: pageLeft + 'px', width: pageW + 'px', height: pageH + 'px' }"
    @mousedown.prevent="startDrag"
    @mousemove="dragging"
    @mouseup="stopDrag"
  >
    <div @drag.prevent :class="{ iframe: true, big: isReaddy, hide: !isShowUnity }">
      <iframe class="iframeBox" ref="iframeRef" :src="assetUrl" :allowtransparency="true" />
    </div>
    <div @drag.prevent :class="{ drag_resize_btn: true, hide: !isMove }" @click="confirmPosition">
      <img src="@/assets/img/unity/right.png" alt="" />
      确定位置
    </div>
    <div ref="unityRef" :class="{ unity: true, hide: !isShowIframe }" @click="openIframe">
      <el-icon color="#fff" size="25">
        <ArrowLeftBold />
      </el-icon>
    </div>
  </div>
</template>

<style scoped lang="scss">
input::-ms-reveal {
  display: none;
}

input::-ms-clear {
  display: none;
}

input[type='password']::-ms-reveal {
  display: none;
}

.unity {
  position: fixed;
  width: 26px;
  height: 140px;
  bottom: 50%;
  right: 0;
  z-index: 1000;
  transform: translateY(50%);
  pointer-events: auto;
  border: 1px solid rgba(238, 238, 238, 1);
  border-radius: 22px 0 0 22px;
  border-right: none;
  background: rgba(97, 97, 98, 0.7);
  display: flex;
  align-items: center;

  &.hide {
    right: -100px;
    opacity: 0;
  }

  img {
    width: 100%;
    height: 100%;
  }

  .loading {
    margin-top: 3px;
    animation: rotates 1.2s infinite linear;
  }

  @keyframes rotates {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
}

.drag_box {
  position: fixed;
  z-index: 100000 !important;

  &::before {
    display: none;
  }

  &.isMove {
    cursor: all-scroll;

    &::before {
      display: block;
    }

    .iframe {
      .iframeBox {
        pointer-events: none;
      }
    }
  }

  &.pointer {
    pointer-events: none;
  }

  .drag_resize_btn {
    top: 15px;
    right: 22px;
    width: 110px;
    height: 40px;
    color: #fff;
    display: flex;
    z-index: 1002;
    cursor: default;
    position: absolute;
    border-radius: 10px;
    align-items: center;
    justify-content: center;
    background-color: #498eff;

    &.hide {
      display: none;
    }

    img {
      width: 16px;
      margin-right: 5px;
      object-fit: contain;
    }
  }

  :deep(.content-container) {
    width: 500px !important;
    height: 500px !important;
  }

  .iframe {
    &.big {
      width: 100%;
      height: 100%;
      position: absolute;
    }

    .iframeBox {
      width: 100%;
      height: 100%;

      .unity_loading {
        height: 94%;
      }

      :deep(.unityContent) {
        width: 100%;
        height: 100%;
      }
    }
  }
}

.iframe {
  width: 250px;
  height: 460px;
  position: fixed;
  right: 0;
  bottom: 30px;
  z-index: 998;
  overflow: hidden;
  transition: 1.2s;

  &.big {
    bottom: 0;
    width: 490px;
    height: 560px;

    .iframeBox {
      position: static;
    }
  }

  .iframeBox {
    top: -295px;
    left: -300px;
    width: 490px;
    height: 560px;
    border: none;
    position: absolute;
  }
}

@keyframes iframein {
  from {
    right: -500px;
  }
  to {
    right: 0;
  }
}

@keyframes iframeout {
  from {
    right: 0;
  }
  to {
    right: -500px;
  }
}

.unityContent {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 550px;
  height: 84%;
  z-index: 1000;
  pointer-events: none;
}

.chatFrame {
  width: 230px;
  z-index: 998;
  position: absolute;
  border: 2px solid #498eff;
  border-radius: 5px;
  top: 340px;
  left: 75px;

  .chatFrameBox {
    width: 100%;
    padding: 8px 14px 10px;
    display: inline-block;
  }
}

.chatFrame:before {
  position: absolute;
  left: 100%;
  top: 50%;
  content: ' ';
  -webkit-transform: translateY(-10px);
  transform: translateY(-10px);
  border: 10px solid transparent;
  border-left-color: #498eff;
}

.chatFrame:after {
  position: absolute;
  left: 100%;
  top: 50%;
  content: ' ';
  transform: translateY(-7px);
  border: 7px solid transparent;
  border-left-color: #fff;
}

.historyList {
  width: 235px;
  height: 150px;
  pointer-events: none;
  margin-left: 32px;
  position: absolute;
  bottom: 110px;
  left: 0;
  overflow-y: auto;

  .historyListUl {
    pointer-events: auto;
  }

  li {
    text-align: left;
    list-style: none !important;
  }

  span {
    background: rgba(255, 255, 255, 0.2);
    color: #ffffff;
    border: 1px solid #498eff;
    border-radius: 16px 16px 16px 0;
    font-size: 16px;
    padding: 6px 16px;
    display: inline-block;
    margin-bottom: 10px;
  }
}

.liList {
  height: 34px;
  text-align: left;
  margin-left: 43px;
  list-style: none !important;
  margin-top: 17px;
  margin-bottom: 17px;

  span {
    background: rgba(255, 255, 255, 0.2);
    color: #ffffff;
    border: 1px solid rgba(255, 255, 255, 0.65);
    border-radius: 16px;
    height: 23px;
    line-height: 34px;
    font-size: 16px;
    padding: 6px 16px;
    text-shadow: 0 0 2px rgba(0, 0, 0, 0.4);
    backdrop-filter: blur(5px);
  }
}

.unityWeb {
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 999;
  width: 100%;
  height: 100%;
  pointer-events: none;
  background-image: url('../../assets/img/unity/chatBackground.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;

  .unityWebUl {
    pointer-events: auto;
    margin-top: 70px;
  }
}

.input:focus {
  border: 1px solid rgba(49, 115, 225, 1) !important;
  color: #ffffff;
  text-shadow: 0 0 2px rgba(0, 0, 0, 0.4);
  outline-style: none;
}

.textInput {
  height: 40px;
  position: absolute;
  bottom: 60px;
  left: 32px;
  pointer-events: auto;
  display: flex;

  .input {
    width: 235px;
    height: 100%;
    background: rgba(255, 255, 255, 0.3) !important;
    border: 1px solid rgba(51, 127, 255, 0.6);
    color: rgba(255, 255, 255);
    font-size: 16px;
    border-radius: 20px;
    padding: 0 16px;
    text-shadow: 0 0 1px rgba(0, 0, 0, 0.4);
  }

  .clickBtn {
    position: absolute;
    top: 10px;
    right: 65px;
    width: 20px;
    height: 20px;
    z-index: 998;
    background-image: url('../../assets/img/unity/enter.png');
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
  }

  .textInputImg {
    width: 40px;
    height: 40px;
    background-image: url('../../assets/img/unity/microphone.png');
    background-size: cover;
    box-shadow:
      inset 0 0 3px 0 #378aff,
      0 4px 10px 0 rgba(0, 0, 0, 0.302);
    border-radius: 50%;
    background-repeat: no-repeat;
    margin-left: 10px;
  }
}

.minification {
  width: 32px;
  height: 32px;
  position: absolute;
  top: 15px;
  right: 21px;
  z-index: 999;
  background-image: url('../../assets/img/unity/minification.png');
  background-repeat: no-repeat;
  background-size: 100%;
  pointer-events: auto;
}

.unityIframe {
  width: 135%;
  height: 472px;
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 999;
  pointer-events: none;
}

.resilience {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 32px;
  height: 206px;
  background-image: url('../../assets/img/unity/resilience.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;

  .leftTriangle {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    width: 17px;
    height: 28px;

    .imgBtn {
      width: 100%;
      height: 100%;
    }
  }
}

.changeShow-enter-active {
  animation: changeShow 0.8s reverse;
}

.changeShow-leave-active {
  animation: changeShow 0.8s;
}

@keyframes changeShow {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(100%);
  }
}
</style>
