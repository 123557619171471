<script setup>
import Recorder from 'js-audio-recorder';
import { nextTick, onBeforeUnmount, onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';
import { ElMessage } from 'element-plus';
import { _isIos, _isWeChat, _isQQ, fetchWithCatch, getCurrentDateTime } from '@/utils/useToolSet';
import { costXfAsrSecond_api } from '@/api/useUnity';
import { useAsrTimeStore } from '@/store/module/useAsrTimeStore';

const router = useRouter();
const key = ref('');
const aihuman_id = ref('');
const getKeyInner = ref(null); // 定时器id
const getKeyNum = ref(100); // 最多轮询次数
const getKeyTime = ref(1000); // 定时器轮询间隔
const openUrl = ref('');
const showModal = ref(false);
const isStart = ref(false);
const showIframe = ref(true);
const voiceStatus = ref(false);
const recorder = ref(null);
const isloading = ref(false);
const assetUrl = ref('https://unitycdn.10wei.top/skbotDev/UnityDev/ShuZiRenDev/1113_8/index.html');
const recorderd = ref(null);
const isShowIframe = ref(true);
const recognitionTxt = ref('点击开始聊天吧');
const btnStatus = ref('UNDEFINED');
const resultText = ref('');
const dialogue = ref(false);

const iframeRef = ref(null);
const myaudioRef = ref(null);
const audioCtx = ref(null);

fetchWithCatch('https://unityapp.10wei.top/unityui/AssetUrl/getAssetUrl').then((res) => {
  assetUrl.value = res.data.shuziren || 'https://unitycdn.10wei.top/skbotDev/UnityDev/ShuZiRenDev/1113_8/index.html';
});

onMounted(() => {
  nextTick(() => {
    iframeRef.value.contentWindow.focus();
  });

  window.addEventListener(
    'message',
    (e) => {
      if (e.data.type === 'JumpLink') {
        router.push({ path: e.data.data });
      } else if (e.data.type === 'NewJumpLink') {
        openUrl.value = e.data.data;
        showModal.value = true;
      } else if (e.data.type === 'Start') {
        isStart.value = true;
        showIframe.value = true;
        isShowIframe.value = true;
        isloading.value = false;
        if (iframeRef.value) {
          iframeRef.value.focus();
        }
      } else if (e.data.type === 'off') {
        showIframe.value = false;
        isShowIframe.value = false;
      } else if (e.data.type === 'startRecord') {
        voiceStatus.value = true;
        window.navigator.mediaDevices
          .getUserMedia({ audio: true })
          .then((stream) => {
            if (voiceStatus.value) {
              if (iframeRef.value && iframeRef.value.contentWindow) {
                iframeRef.value.contentWindow.postMessage(
                  {
                    data: true,
                    type: 'startRecord'
                  },
                  '*'
                );
              }
              recorder.value = new Recorder(stream, {
                sampleBits: 16,
                sampleRate: 16000,
                numChannels: 1
              });
              recorder.value.start();
            }
          })
          .catch((err) => {
            console.log(err);
            if (iframeRef.value && iframeRef.value.contentWindow) {
              iframeRef.value.contentWindow.postMessage(
                {
                  info: '开始当前浏览器已禁止开启麦克风录音',
                  type: 'recordErr'
                },
                '*'
              );
            }
            messageHandle({
              type: 'error',
              message: '开始当前浏览器已禁止开启麦克风录音'
            });
            voiceStatus.value = false;
          });
      } else if (e.data.type === 'stopRecord') {
        voiceStatus.value = false;
        if (recorder.value) {
          recorder.value.stop();
          if (e.data.data && !e.data.data.isCancal && e.data.data.isLongTouch) {
            let wavBlob = recorder.value.getWAVBlob(); // 获取 WAV 数据
            // 使用该插件只能导出wav格式的音频，然后把wav格式的音频转化为文件格式，方便使用接口的形式传给后端。
            let file = new File([wavBlob], 'audio.wav', {
              type: 'wav',
              lastModified: Date.now()
            });
            if (iframeRef.value && iframeRef.value.contentWindow) {
              iframeRef.value.contentWindow.postMessage(
                {
                  data: file,
                  type: 'stopRecord'
                },
                '*'
              );
            }
          } else {
            // 已取消录音
            if (iframeRef.value && iframeRef.value.contentWindow) {
              iframeRef.value.contentWindow.postMessage(
                {
                  data: null,
                  type: 'stopRecord'
                },
                '*'
              );
            }
          }
        }
      } else if (e.data.type === 'changeVoiceStatus') {
        voiceStatus.value = e.data.data;
      } else if (e.data.type === 'message') {
        messageHandle(e.data.data);
      } else if (e.data.type === 'audioPlayHandle') {
        audioPlayHandle(e.data.data);
      } else if (e.data.type === 'audioPauseHandle') {
        audioPauseHandle();
      } else if (e.data.type === 'startRecordHandle') {
        // startRecordHandle();
      } else if (e.data.type === 'recorderd') {
        if (e.data.data === 'start') {
          recorderd.value.start({
            sampleRate: 16000,
            frameSize: 1280
          });
        } else {
          recorderd.value && recorderd.value[e.data.data] && recorderd.value[e.data.data]();
        }
      } else if (e.data.type === 'dialogue') {
        dialogue.value = e.data.data;
      }
    },
    false
  );

  navigator.mediaDevices.getUserMedia({ audio: true });

  let url = `https://unityapp.10wei.top/unityweb/ComDomain/getDomainKey?domain=${location.hostname}`;
  fetchWithCatch(url).then((res) => {
    let data = res.data;
    if (data) {
      key.value = data.key;
      aihuman_id.value = data.aihuman_id;
    }
    if (key.value) {
      initPolling(1);
    }
  });

  const AsrTimeStore = useAsrTimeStore();
  // eslint-disable-next-line no-undef
  recorderd.value = new RecorderManager('./recorder');
  recorderd.value.onStart = () => {
    AsrTimeStore.start_time = getCurrentDateTime();
    changeBtnStatus('OPEN');
  };
  recorderd.value.onFrameRecorded = ({ isLastFrame, frameBuffer }) => {
    if (iframeRef.value && iframeRef.value.contentWindow) {
      iframeRef.value.contentWindow.postMessage(
        {
          data: { data: new Int8Array(frameBuffer), isEnd: isLastFrame },
          type: 'onFrameRecorded'
        },
        '*'
      );
    }
  };
  recorderd.value.onStop = async () => {
    if (AsrTimeStore.start_time) {
      const { code, info } = await costXfAsrSecond_api({
        key: key.value,
        start_time: AsrTimeStore.start_time,
        end_time: getCurrentDateTime()
      });
      if (code === 0) {
        ElMessage.error(info || '获取key失败');
      }
      AsrTimeStore.start_time = '';
    }
    dialogue.value = false;
    changeBtnStatus('CLOSED');
  };
});

onBeforeUnmount(() => {
  if (getKeyInner.value) {
    clearTimeout(getKeyInner.value);
    getKeyInner.value = null;
  }
});

const iframeLoad = () => {
  if (iframeRef.value) {
    iframeRef.value.focus();
  }
};

const configWx = (callback, error) => {
  // eslint-disable-next-line no-undef
  wx.config({
    debug: false, // 开启调试模式,调用的所有 api 的返回值会在客户端 alert 出来，若要查看传入的参数，可以在 pc 端打开，参数信息会通过 log 打出，仅在 pc 端时才会打印。
    appId: 'res.data.appId', // 必填，公众号的唯一标识
    timestamp: 'res.data.timestamp', // 必填，生成签名的时间戳
    nonceStr: 'res.data.nonceStr', // 必填，生成签名的随机串
    signature: 'res.data.signature', // 必填，签名
    jsApiList: [] // 必填，需要使用的 JS 接口列表
  });
  // eslint-disable-next-line no-undef
  wx.ready(() => {
    if (callback) {
      callback();
    }
  });
  // eslint-disable-next-line no-undef
  wx.error((res) => {
    console.log('err', res);
    if (error) {
      error();
    }
    if (callback) {
      nextTick(() => {
        callback();
      });
    }
  });
};

const pausePlay = () => {
  //暂停播放
  let myAuto = myaudioRef.value; // document.getElementById('myaudio');
  myAuto.pause();
};
const autoPlay = (_url, needConfigWx) => {
  //自动播放
  let myAuto = myaudioRef.value; // document.getElementById('myaudio');
  myAuto.src = _url; //MP3路径
  myAuto.load();
  if (needConfigWx) {
    configWx(() => {
      myAuto.play();
    });
  } else {
    myAuto.play();
  }
};

const audioPlayHandle = (audioUrl) => {
  if (audioUrl) {
    audioPauseHandle();
    if (_isIos() && (_isWeChat() || _isQQ())) {
      autoPlay(audioUrl, _isWeChat());
    } else {
      fetch(audioUrl)
        .then((res) => res.arrayBuffer())
        .then((res) => {
          if (audioCtx.value) {
            audioCtx.value.suspend();
            audioCtx.value.close();
          }
          const ctx = new window.AudioContext() || new window.webkitAudioContext();
          ctx.decodeAudioData(
            res,
            function (buffer) {
              // 创建AudioBufferSourceNode对象
              let source = ctx.createBufferSource();
              source.buffer = buffer;
              source.connect(ctx.destination);
              // 指定位置开始播放
              source.start(0);
            },
            function (e) {
              console.info('处理出错 e', e);
            }
          );
          audioCtx.value = ctx;
        });
    }
  }
};
const audioPauseHandle = () => {
  if (_isIos() && (_isWeChat() || _isQQ())) {
    pausePlay();
  } else if (audioCtx.value) {
    audioCtx.value.suspend();
    audioCtx.value.close();
    audioCtx.value = null;
  }
};

const unityOpenHandle = () => {
  if (isStart.value) {
    showIframe.value = true;
    isShowIframe.value = true;
    iframeRef.value.contentWindow.postMessage(
      {
        data: true,
        type: 'open'
      },
      '*'
    );
    iframeRef.value.contentWindow.focus();
  } else {
    showIframe.value = true;
    isShowIframe.value = true;
    iframeRef.value.contentWindow.postMessage(
      {
        data: true,
        type: 'show'
      },
      '*'
    );
    iframeRef.value.contentWindow.focus();
  }
};

const closeModalHandle = () => {
  showModal.value = false;
};
const openUrlHandle = () => {
  closeModalHandle();
  window.open(openUrl.value);
};
const messageHandle = (option) => {
  ElMessage(option);
};

const initPolling = (num) => {
  let iframe = iframeRef.value;
  if ((iframe && iframe.contentWindow) || num >= getKeyNum.value) {
    if (iframe && iframe.contentWindow) {
      // noinspection JSUnusedGlobalSymbols
      iframe.onload = () => {
        iframe.contentWindow.postMessage(
          {
            data: key.value,
            type: 'key'
          },
          '*'
        );
      };
    }
    if (getKeyInner.value) {
      clearTimeout(getKeyInner);
      getKeyInner.value = null;
    }
  } else {
    getKeyInner.value = setTimeout(() => {
      initPolling(num + 1);
    }, getKeyTime.value);
  }
};
const changeBtnStatus = (status) => {
  btnStatus.value = status;
  if (status === 'CONNECTING') {
    recognitionTxt.value = '加载中...';
    resultText.value = '';
  } else if (status === 'OPEN') {
    recognitionTxt.value = '请说，我正在听';
  } else if (status === 'CLOSING') {
    recognitionTxt.value = '抱歉，我没听清你说什么';
  } else if (status === 'CLOSED') {
    recognitionTxt.value = '点击开始聊天吧';
  }
  if (iframeRef.value && iframeRef.value.contentWindow) {
    iframeRef.value.contentWindow.postMessage(
      {
        data: status,
        type: 'changeBtnStatus'
      },
      '*'
    );
  }
};
</script>

<template>
  <div>
    <div class="sk_bot">
      <div :class="{ unity: true, dis: showIframe }" ref="unity" @click="unityOpenHandle">
        <img class="btnImg" src="https://unitycdn.10wei.top/Texture/SKBOT/open.png" alt="" />
      </div>
      <div :class="{ iframe: true, dis: !isShowIframe }">
        <iframe ref="iframeRef" @load="iframeLoad" class="iframeBox" :src="assetUrl" />
      </div>
      <div class="sk_modal" v-show="showModal">
        <div class="sk_modal_box">
          <div class="sk_modal_bd">即将打开外链，是否继续?</div>
          <div class="sk_modal_btns">
            <div class="sk_modal_btn cel" @click="closeModalHandle">取消</div>
            <div class="sk_modal_btn act" @click="openUrlHandle">确定</div>
          </div>
        </div>
      </div>
      <div class="audio" style="display: none">
        <audio id="myaudio" ref="myaudioRef" />
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.sk_bot {
  .unity {
    right: 0;
    position: fixed;
    width: 30px;
    height: 100px;
    bottom: 100px;
    z-index: 10000;
    transition: 1.5s;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    &.dis {
      right: -100vw;
    }

    img {
      width: 18px;
      height: 80px;
      object-fit: contain;
    }
  }

  .loading {
    width: 88px;
    height: 80px;
    position: fixed;
    right: 23px;
    bottom: 100px;
    z-index: 1000;
    display: flex;
    border-radius: 8px;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background: rgba(255, 255, 255, 0.9);
    box-shadow: inset 0 0 3px 0 #378aff;

    img {
      width: 22px;
      height: 22px;
      object-fit: contain;
      animation: 1.2s rotate linear infinite;
    }

    .txt {
      color: #407ee4;
      margin-top: 5px;
    }
  }

  .iframe {
    width: 200px;
    height: 300px;
    position: fixed;
    left: 0;
    bottom: 0;
    z-index: 998;
    transition: 1.5s;

    &.dis {
      left: 100vw;
    }

    .iframeBox {
      bottom: 0;
      left: 0;
      width: 100vw;
      height: 300px;
      border: none;
      position: absolute;
      background: none !important;
    }
  }

  .sk_bot_message {
    min-width: 380px;
    padding: 8px 16px;
    margin: 0;
    box-sizing: border-box;
    border-radius: 4px;
    background-color: #fff;
    overflow: hidden;
    opacity: 1;
    display: flex;
    align-items: center;
    position: fixed;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
    transition: opacity 0.2s;
    z-index: 10000;
    border: 1px solid;

    p {
      margin: 0;
    }
  }

  .sk_modal {
    position: fixed;
    z-index: 99999;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);

    .sk_modal_box {
      position: absolute;
      width: 80%;
      max-width: 300px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: #ffffff;
      text-align: center;
      border-radius: 3px;
      overflow: hidden;

      .sk_modal_bd {
        padding: 20px 24px 20px;
        min-height: 40px;
        font-size: 15px;
        line-height: 1.4;
        box-sizing: border-box;
        word-wrap: break-word;
        word-break: break-all;
        white-space: pre-wrap;
        color: #999999;
        max-height: 400px;
        overflow-x: hidden;
        overflow-y: auto;
      }

      .sk_modal_btns {
        position: relative;
        line-height: 48px;
        font-size: 18px;
        display: flex;

        &::after {
          content: ' ';
          position: absolute;
          left: 0;
          top: 0;
          right: 0;
          height: 1px;
          border-top: 1px solid #d5d5d6;
          color: #d5d5d6;
          transform-origin: 0 0;
          transform: scaleY(0.5);
        }

        .sk_modal_btn {
          display: block;
          flex: 1;
          color: #000;
          text-decoration: none;
          -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
          position: relative;
          cursor: pointer;

          .act {
            color: #007aff;

            &::after {
              content: ' ';
              position: absolute;
              left: 0;
              top: 0;
              width: 1px;
              bottom: 0;
              border-left: 1px solid #d5d5d6;
              color: #d5d5d6;
              transform-origin: 0 0;
              transform: scaleX(0.5);
            }
          }
        }
      }
    }
  }
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
