import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';
import 'animate.css';
import * as ElementPlusIconsVue from '@element-plus/icons-vue';

// 引入公共css
import '@/styles/css/global.css';
import '@/styles/css/element-plus.css';

export default function (app) {
  app.use(ElementPlus, { size: 'default', zIndex: 1000 });

  for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component);
  }
}
