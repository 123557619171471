import { createSSRApp } from 'vue';
import { renderToString } from 'vue/server-renderer';
import App from './App.vue';
import router from './router';
import pinia from './store'; //引入

// 自定义样式
import initializeStyle from '@/styles/styleMain';

// 自定义方法
import CustomDirectives from '@/plugins/CustomDirectives';

// 初始化app
const initializeApp = () => {
  const app = createSSRApp(App);
  app.use(pinia);
  app.use(router);

  initializeStyle(app);
  CustomDirectives(app);
  return app;
};

const app = initializeApp();
const ctx = {
  keywords: '时空节拍，超写实数字人，虚拟偶像，十维元客，十维元语，元宇宙，AIbobo，AIhuman',
  description:
    '时空节拍（杭州）科技有限公司致力于超写实数字虚拟人定制设计与数字克隆人自动直播系统开发！为用户提供超写实3D建模+五官、骨骼绑定+驱动绑定+多平台直播系统分发一体化解决方案!公司提供的场景搭建,让用户在元宇宙空间随意创作,适用于私人直播间、展厅、数字城市等多种场景。'
};

(async () => {
  await renderToString(app, ctx);
  app.mount('#app');
})();
