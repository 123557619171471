<template>
  <el-config-provider :locale="zhCn" :message="MessageConfig">
    <router-view />

    <UnityWeb v-if="!isMobile" />
    <UnityMobile v-else />

    <CustomerServiceWindow v-if="!isMobile" />
    <CustomerServiceWindow_mobile v-else />
  </el-config-provider>
</template>
<script setup>
import zhCn from 'element-plus/dist/locale/zh-cn.mjs';
import { onBeforeMount, watch, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import UnityWeb from '@/components/unity/unityWeb.vue';
import UnityMobile from '@/components/unity/unityMobile.vue';
import { _isMobile } from '@/utils/useToolSet';
import CustomerServiceWindow from '@/components/Customize/CustomerServiceWindow.vue';
import CustomerServiceWindow_mobile from '@/components/Customize/CustomerServiceWindow_mobile.vue';

const MessageConfig = ref({
  max: 1
});

let route = useRoute();
let router = useRouter();
const isMobile = ref(_isMobile());
let isResize = ref(false);

const updateComponentAndRoute = () => {
  if (isMobile.value && !route.path.startsWith('/Mobile')) {
    router.replace('/Mobile'); // 假设 /Mobile 是动态路由前缀
  } else if (!isMobile.value && route.path.startsWith('/Mobile')) {
    router.replace('/'); // 假设 / 是应用的根路径
  }
};

onBeforeMount(() => {
  updateComponentAndRoute();
});
watch([route, isMobile], () => {
  if (isMobile.value) {
    if (!isResize.value) {
      document.documentElement.style.fontSize = document.documentElement.clientWidth / 720 + 'px';
      isResize.value = true;
    }
    if (!route.path.startsWith('/Mobile')) {
      updateComponentAndRoute();
    }
  } else {
    document.documentElement.style.fontSize = 'none';
    isResize.value = false;
    updateComponentAndRoute();
  }
});
</script>
