<!--suppress HtmlUnknownAttribute -->
<script setup>
import { SetConsult_api } from '@/api/useForms';
import { ElMessage } from 'element-plus';
import { ref } from 'vue';

const name = ref('');
const phone = ref();
const submitLoading = ref(false);
const handleSubmit = async () => {
  if (!name.value) {
    ElMessage.error('请输入姓名');
    return;
  }
  if (!phone.value) {
    ElMessage.error('请输手机号');
    return;
  }
  submitLoading.value = true;
  try {
    const { code, info } = await SetConsult_api({
      name: name.value,
      phone: phone.value,
      company: '',
      email: '',
      demand: ''
    });
    if (code === 0) {
      ElMessage.error(info || '操作失败');
    } else {
      ElMessage.success(info || '操作成功');
      name.value = '';
      phone.value = '';
      // eslint-disable-next-line no-undef
      GetCustomEvent();
    }
    submitLoading.value = false;
  } catch (error) {
    submitLoading.value = false;
    ElMessage.error(error || '操作失败');
  }
};

const isDragging = ref(false);
const isMove = ref(false);
const dragOffsetX = ref(0);
const dragOffsetY = ref(0);
const pageW = ref(240);
const pageH = ref(360);
const pageTop = ref(window.innerHeight - pageH.value - 48);
const pageLeft = ref(16);
const startDrag = (e) => {
  if (!isMove.value) return;
  isDragging.value = true;
  const rect = e.target.getBoundingClientRect();
  dragOffsetX.value = e.clientX - rect.left;
  dragOffsetY.value = e.clientY - rect.top;
};
const dragging = (event) => {
  if (!isDragging.value) return;
  pageLeft.value = event.clientX - dragOffsetX.value;
  pageTop.value = event.clientY - dragOffsetY.value;

  if (pageLeft.value < 0) {
    pageLeft.value = 0;
  } else if (pageLeft.value > window.innerWidth - pageW.value) {
    pageLeft.value = window.innerWidth - pageW.value;
  }
  if (pageTop.value < 0) {
    pageTop.value = 0;
  } else if (pageTop.value > window.innerHeight - pageH.value) {
    pageTop.value = window.innerHeight - pageH.value;
  }
};
const stopDrag = () => {
  isDragging.value = false;
};
</script>

<template>
  <div
    :class="{ CustomerServiceWindow: true, move: isMove }"
    :style="{ top: pageTop + 'px', left: pageLeft + 'px', width: pageW + 'px', height: pageH + 'px' }"
    @mousedown.prevent="startDrag"
    @mousemove="dragging"
    @mouseup="stopDrag"
  >
    <img class="header" draggable="false" src="@/assets/img/serviceWindow/zx.png" alt="" />
    <div class="body">
      <div class="border-img" />
      <div class="cont">
        <div class="title">
          <img draggable="false" src="@/assets/img/serviceWindow/title.png" alt="" />
          <div class="drag_btn">
            <svg
              v-show="isMove"
              t="1724147759391"
              class="icon"
              viewBox="0 0 1024 1024"
              xmlns="http://www.w3.org/2000/svg"
              p-id="1038"
              width="16"
              height="16"
            >
              <path
                d="M819.2 275.2c-19.2-19.2-51.2-19.2-70.4 0L396.8 633.6l-128-128c-19.2-19.2-51.2-19.2-70.4 0-19.2 19.2-19.2 51.2 0 70.4l160 160c12.8 12.8 25.6 12.8 38.4 12.8 12.8 0 25.6-6.4 38.4-12.8l384-384C838.4 326.4 838.4 294.4 819.2 275.2z"
                p-id="1039"
                fill="#0095FF"
              />
            </svg>
            <span @click="isMove = !isMove">{{ isMove ? '确认位置' : '点击可拖动' }}</span>
          </div>
        </div>

        <dl class="info">
          <dd>欢迎来到时空节拍官网！</dd>
          <dd>
            我们专注于为客户提供全球“数智人”全链路应用生态。专业提供3D/2D数字人定制、数字人智能交互、智能播报、3D视频一键生成、XR虚拟直播等工具服务。
          </dd>
          <dd>详情请联系韩经理电话/微信：13734467072</dd>
        </dl>
        <ul>
          <li>
            <svg
              t="1724143060144"
              class="icon"
              viewBox="0 0 1024 1024"
              xmlns="http://www.w3.org/2000/svg"
              p-id="864"
              width="16"
              height="16"
            >
              <path
                d="M512 42.666667c153.173333 0 277.333333 124.16 277.333333 277.333333 0 93.653333-46.4 176.426667-117.461333 226.645333C823.978667 613.866667 938.666667 770.346667 938.666667 938.666667a42.666667 42.666667 0 1 1-85.333334 0c0-176.725333-164.608-341.333333-341.333333-341.333334-176.725333 0-341.333333 164.608-341.333333 341.333334a42.666667 42.666667 0 1 1-85.333334 0c0-168.341333 114.709333-324.8 266.794667-392.021334A276.970667 276.970667 0 0 1 234.666667 320c0-153.173333 124.16-277.333333 277.333333-277.333333z m0 85.333333a192 192 0 1 0 0 384 192 192 0 0 0 0-384z"
                fill="#A8ABB2"
                p-id="865"
              />
            </svg>
            <el-input v-model="name" placeholder="请输入您的姓名" />
          </li>
          <li>
            <svg
              t="1724143174373"
              class="icon"
              viewBox="0 0 1024 1024"
              xmlns="http://www.w3.org/2000/svg"
              p-id="1148"
              width="16"
              height="16"
            >
              <path
                d="M704 64c117.824 0 213.333333 95.509333 213.333333 213.333333v490.666667c0 117.824-95.509333 213.333333-213.333333 213.333333H320C202.176 981.333333 106.666667 885.824 106.666667 768V277.333333C106.666667 159.509333 202.176 64 320 64h384z m0 74.666667H320a138.666667 138.666667 0 0 0-138.581333 133.696L181.333333 277.333333v490.666667a138.666667 138.666667 0 0 0 133.696 138.581333L320 906.666667h384a138.666667 138.666667 0 0 0 138.581333-133.696L842.666667 768V277.333333a138.666667 138.666667 0 0 0-133.696-138.581333L704 138.666667z"
                fill="#A8ABB2"
                p-id="1149"
              />
              <path
                d="M618.666667 730.666667a37.333333 37.333333 0 0 1 3.072 74.538666L618.666667 805.333333H405.333333a37.333333 37.333333 0 0 1-3.072-74.538666L405.333333 730.666667h213.333334z"
                fill="#A8ABB2"
                p-id="1150"
              />
            </svg>
            <el-input v-model="phone" placeholder="请输入您的手机号" />
          </li>
        </ul>
        <div class="submit_btn">
          <el-button :loading="submitLoading" @click="handleSubmit">提交</el-button>
        </div>
      </div>
    </div>
  </div>

  <div class="wx-service">
    <img src="@/assets/img/footer/shangwuzixun1.png" alt="" />
    <p>微信客服</p>
  </div>
</template>

<style scoped lang="scss">
.CustomerServiceWindow {
  position: fixed;
  z-index: 1000;
  width: 240px;
  bottom: 48px;
  left: 16px;
  height: 300px;

  &.move {
    cursor: move;
  }

  .header {
    position: absolute;
    width: 125%;
    left: -15px;
    top: -118px;
  }

  .body {
    width: 100%;
    height: 360px;
    background-image: linear-gradient(
      180deg,
      rgba(6.000000117346644, 192.00000375509262, 254.00000005960464, 1),
      rgba(0, 148.7499949336052, 255, 1)
    );
    border-radius: 6px;
    position: relative;
    box-shadow: 0 0 30px 0 rgba(143, 143, 143, 0.6);

    .cont {
      position: absolute;
      width: calc(100% - 3px);
      height: calc(100% - 3px);
      background: linear-gradient(180deg, #e1f7ff 0%, #ffffff 22%);
      top: 50%;
      left: 50%;
      border-radius: 6px;
      transform: translate(-50%, -50%);

      .title {
        width: 100%;
        height: 45px;
        position: relative;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        .drag_btn {
          display: flex;
          align-items: center;
          position: absolute;
          top: 50%;
          right: 15px;
          transform: translateY(-50%);

          span {
            cursor: pointer;
            font-family: 'Alibaba PuHuiTi 3', 'Alibaba PuHuiTi 30', 'Microsoft YaHei', serif;
            font-weight: 400;
            font-size: 12px;
            color: #0095ff;
            line-height: 17px;
          }
        }
      }

      .info {
        padding: 10px 16px 0;
        font-family: 'Alibaba PuHuiTi 3', 'Alibaba PuHuiTi 30', 'Microsoft YaHei', serif;
        font-weight: 400;
        font-size: 13px;
        color: #3d3d3d;
        line-height: 19px;
        margin-bottom: 16px;

        dd {
          text-align: justify;
        }
      }

      ul {
        padding: 0 16px;

        li {
          height: 32px;
          background: #ffffff;
          border-radius: 2px;
          border: 1px solid #dcdfe6;
          display: flex;
          align-items: center;
          padding: 0 8px;

          & + li {
            margin-top: 16px;
          }

          :deep(.el-input) {
            height: 100%;

            .el-input__wrapper {
              box-shadow: none;

              &:hover {
                box-shadow: none;
              }

              input {
                color: #a8abb2;
              }
            }
          }
        }
      }
    }

    .submit_btn {
      text-align: right;
      padding: 12px 16px 0;

      .el-button {
        width: 90px;
        height: 32px;
        background: #0095ff;
        border-radius: 2px;
        color: #fff;
      }
    }
  }
}

.wx-service {
  position: fixed;
  bottom: 545px;
  right: 20px;
  width: 122px;
  height: 148px;
  background: #ffffff;
  box-shadow: 0 0 30px 0 rgba(143, 143, 143, 0.6);
  border-radius: 6px;
  padding: 8px;
  border: 1px solid;
  border-image: linear-gradient(
      180deg,
      rgba(6.000000117346644, 192.00000375509262, 254.00000005960464, 1),
      rgba(0, 148.7499949336052, 255, 1)
    )
    0 0;

  img {
    width: 100%;
    height: 106px;
  }

  p {
    font-weight: 400;
    font-size: 14px;
    color: #3d3d3d;
    line-height: 20px;
    text-align: center;
  }
}
</style>
