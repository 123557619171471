<!--suppress HtmlUnknownAttribute -->
<script setup>
import { SetConsult_api } from '@/api/useForms';
import { ElMessage } from 'element-plus';
import { ref } from 'vue';

const name = ref('');
const phone = ref();
const submitLoading = ref(false);
const handleSubmit = async () => {
  if (!name.value) {
    ElMessage.error('请输入姓名');
    return;
  }
  if (!phone.value) {
    ElMessage.error('请输手机号');
    return;
  }
  submitLoading.value = true;
  try {
    const { code, info } = await SetConsult_api({
      name: name.value,
      phone: phone.value,
      company: '',
      email: '',
      demand: ''
    });
    if (code === 0) {
      ElMessage.error(info || '操作失败');
    } else {
      ElMessage.success(info || '操作成功');
      name.value = '';
      phone.value = '';
      // eslint-disable-next-line no-undef
      GetCustomEvent();
    }
    submitLoading.value = false;
  } catch (error) {
    submitLoading.value = false;
    ElMessage.error(error || '操作失败');
  }
};

const isShow = ref(false);
const pageH = ref(0);
const pageW = ref(218);
const pageLeft = ref(16);
const pageTop = ref(0);
pageTop.value = window.innerHeight - 16;
const disX = ref(0);
const disY = ref(0);
const DropEl = ref(null);
let isDragging = false;
let touchStartTime = '';
const touchstart = (event) => {
  disX.value = event.targetTouches[0].pageX - DropEl.value.offsetLeft;
  disY.value = event.targetTouches[0].pageY - DropEl.value.offsetTop;
  touchStartTime = Date.now();
};
const touchmove = (event) => {
  const moveThreshold = 10; // 阈值可以根据实际情况调整
  let clientX = event.touches[0].clientX;
  let clientY = event.touches[0].clientY;
  if (Math.abs(clientX - disX.value) > moveThreshold || Math.abs(clientY - disY.value) > moveThreshold) {
    isDragging = true;
    // 2，获取手指移动的实时位置  需要减去位置差
    pageLeft.value = event.targetTouches[0].pageX - disX.value;
    pageTop.value = event.targetTouches[0].pageY - disY.value;

    // 4，控制范围：在元素 被拖拽的过程中 判断 元素的定位值 是否到达边界 如果到了 就不能在走了
    if (pageLeft.value <= 16) {
      pageLeft.value = 16;
    }
    // 上边界
    if (pageTop.value <= 65) {
      pageTop.value = 65;
    }
    //下边界  容器高度 - 拖动元素高度
    if (pageTop.value >= window.innerHeight - pageH.value) {
      pageTop.value = window.innerHeight - pageH.value - 16;
    }
    //右边界   容器宽度 - 拖动元素宽度
    if (pageLeft.value >= window.innerWidth - pageW.value) {
      pageLeft.value = window.innerWidth - pageW.value - 16;
    }
  }
};
const touchend = () => {
  if (!isDragging) {
    // 触发点击事件
    const clickThreshold = 200; // 点击的时间阈值
    if (Date.now() - touchStartTime < clickThreshold) {
      handleShow();
    }
  } else {
    isDragging = false;
  }
};
const handleShow = () => {
  isShow.value = !isShow.value;
  pageH.value = isShow.value ? 297 : 0;
  pageTop.value = window.innerHeight - 296 - 16;
};

const wxServiceShow = ref(false);
</script>

<template>
  <div
    ref="DropEl"
    :class="{ CustomerServiceWindow: true, isShow: isShow }"
    :style="{ top: pageTop + 'px', left: pageLeft + 'px', width: pageW + 'px', height: pageH + 'px' }"
  >
    <img
      class="header"
      draggable="false"
      src="@/assets/img/serviceWindow/zx1.png"
      alt=""
      @touchmove.prevent="touchmove"
      @touchstart.prevent="touchstart"
      @touchend.prevent="touchend"
    />
    <div class="body">
      <div class="border-img" />
      <div class="cont">
        <div class="title">
          <img draggable="false" src="@/assets/img/serviceWindow/title1.png" alt="" />
        </div>

        <dl class="info">
          <dd>欢迎来到时空节拍官网！</dd>
          <dd>
            我们专注于为客户提供全球“数智人”全链路应用生态。专业提供3D/2D数字人定制、数字人智能交互、智能播报、3D视频一键生成、XR虚拟直播等工具服务。
          </dd>
          <dd>详情请联系韩经理电话/微信：13734467072</dd>
        </dl>
        <ul>
          <li>
            <svg
              t="1724143060144"
              class="icon"
              viewBox="0 0 1024 1024"
              xmlns="http://www.w3.org/2000/svg"
              p-id="864"
              width="16"
              height="16"
            >
              <path
                d="M512 42.666667c153.173333 0 277.333333 124.16 277.333333 277.333333 0 93.653333-46.4 176.426667-117.461333 226.645333C823.978667 613.866667 938.666667 770.346667 938.666667 938.666667a42.666667 42.666667 0 1 1-85.333334 0c0-176.725333-164.608-341.333333-341.333333-341.333334-176.725333 0-341.333333 164.608-341.333333 341.333334a42.666667 42.666667 0 1 1-85.333334 0c0-168.341333 114.709333-324.8 266.794667-392.021334A276.970667 276.970667 0 0 1 234.666667 320c0-153.173333 124.16-277.333333 277.333333-277.333333z m0 85.333333a192 192 0 1 0 0 384 192 192 0 0 0 0-384z"
                fill="#A8ABB2"
                p-id="865"
              />
            </svg>
            <el-input v-model="name" placeholder="请输入您的姓名" />
          </li>
          <li>
            <svg
              t="1724143174373"
              class="icon"
              viewBox="0 0 1024 1024"
              xmlns="http://www.w3.org/2000/svg"
              p-id="1148"
              width="16"
              height="16"
            >
              <path
                d="M704 64c117.824 0 213.333333 95.509333 213.333333 213.333333v490.666667c0 117.824-95.509333 213.333333-213.333333 213.333333H320C202.176 981.333333 106.666667 885.824 106.666667 768V277.333333C106.666667 159.509333 202.176 64 320 64h384z m0 74.666667H320a138.666667 138.666667 0 0 0-138.581333 133.696L181.333333 277.333333v490.666667a138.666667 138.666667 0 0 0 133.696 138.581333L320 906.666667h384a138.666667 138.666667 0 0 0 138.581333-133.696L842.666667 768V277.333333a138.666667 138.666667 0 0 0-133.696-138.581333L704 138.666667z"
                fill="#A8ABB2"
                p-id="1149"
              />
              <path
                d="M618.666667 730.666667a37.333333 37.333333 0 0 1 3.072 74.538666L618.666667 805.333333H405.333333a37.333333 37.333333 0 0 1-3.072-74.538666L405.333333 730.666667h213.333334z"
                fill="#A8ABB2"
                p-id="1150"
              />
            </svg>
            <el-input v-model="phone" placeholder="请输入您的手机号" />
          </li>
        </ul>
        <div class="submit_btn">
          <el-button :loading="submitLoading" @click="handleSubmit">提交</el-button>
        </div>
      </div>
    </div>
  </div>
  <div class="wx-service">
    <div class="up" @touchstart="wxServiceShow = !wxServiceShow">
      <svg
        t="1724226997619"
        class="icon"
        viewBox="0 0 1024 1024"
        xmlns="http://www.w3.org/2000/svg"
        p-id="891"
        width="18"
        height="18"
      >
        <path
          d="M48.384 45.376l412.224 0 0 84.992-412.224 0 0-84.992ZM565.44 45.376l238.656 0 0 84.992-238.656 0 0-84.992ZM891.584 45.376l86.72 0 0 86.72-86.72 0 0-86.72ZM48.384 374.4l412.224 0 0 85.056-412.224 0 0-85.056ZM45.696 45.376l84.992 0 0 412.224-84.992 0 0-412.224ZM375.616 45.376l84.992 0 0 412.224-84.992 0 0-412.224ZM189.376 200.832l115.712 0 0 115.712-115.712 0 0-115.712ZM565.44 130.368l82.752 0 0 87.232-82.752 0 0-87.232ZM804.032 130.368l90.752 0 0 160.512-90.752 0 0-160.512ZM891.584 217.6l86.72 0 0 151.872-86.72 0 0-151.872ZM651.328 282.688l152.768 0 0 109.312-152.768 0 0-109.312ZM736.832 369.408l155.392 0 0 88.128-155.392 0 0-88.128ZM565.952 372.16l85.376 0 0 85.376-85.376 0 0-85.376ZM45.696 542.784l84.992 0 0 192.576-84.992 0 0-192.576ZM130.88 717.248l91.968 0 0 92.032-91.968 0 0-92.032ZM45.696 805.632l85.184 0 0 173.056-85.184 0 0-173.056ZM217.664 542.784l261.696 0 0 105.728-261.696 0 0-105.728ZM281.344 639.104l109.184 0 0 100.48-109.184 0 0-100.48ZM370.176 717.248l109.184 0 0 174.848-109.184 0 0-174.848ZM285.44 805.632l105.088 0 0 173.056-105.088 0 0-173.056ZM197.952 869.12l102.016 0 0 109.568-102.016 0 0-109.568ZM629.184 542.784l195.264 0 0 174.464-195.264 0 0-174.464ZM871.168 542.784l107.136 0 0 107.136-107.136 0 0-107.136ZM545.088 630.016l107.136 0 0 194.304-107.136 0 0-194.304ZM716.672 692.8l107.776 0 0 111.872-107.776 0 0-111.872ZM545.088 869.12l107.136 0 0 109.568-107.136 0 0-109.568ZM802.816 892.096l175.488 0 0 86.592-175.488 0 0-86.592ZM890.56 804.672l87.744 0 0 105.728-87.744 0 0-105.728Z"
          fill="#272636"
          p-id="892"
        />
      </svg>
    </div>
    <div class="down" v-show="wxServiceShow">
      <img src="@/assets/img/footer/shangwuzixun1.png" alt="" />
      <p>微信客服</p>
    </div>
  </div>
</template>

<style scoped lang="scss">
.CustomerServiceWindow {
  position: fixed;
  z-index: 1000;
  width: 218px;
  bottom: 48px;
  left: 16px;

  &.move {
    cursor: move;
  }

  .header {
    position: absolute;
    top: -90px;
    left: -21px;
    width: 213px;
  }

  &.isShow .body {
    height: 297px;
  }

  .body {
    width: 100%;
    height: 0;
    background-image: linear-gradient(
      180deg,
      rgba(6.000000117346644, 192.00000375509262, 254.00000005960464, 1),
      rgba(0, 148.7499949336052, 255, 1)
    );
    border-radius: 6px;
    position: relative;
    box-shadow: 0 0 30px 0 rgba(143, 143, 143, 0.6);
    overflow: hidden;

    .cont {
      position: absolute;
      width: calc(100% - 2px);
      height: calc(100% - 2px);
      background: linear-gradient(180deg, #e1f7ff 0%, #ffffff 22%);
      top: 50%;
      left: 50%;
      border-radius: 6px;
      transform: translate(-50%, -50%);

      .title {
        width: 100%;
        height: 2.5rem;
        position: relative;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .info {
        padding: 0.5rem 0.5625rem 0;
        font-family: 'Alibaba PuHuiTi 3', 'Alibaba PuHuiTi 30', 'Microsoft YaHei', serif;
        font-weight: 400;
        font-size: 0.625rem;
        color: #3d3d3d;
        line-height: 1rem;
        margin-bottom: 0.625rem;
      }

      ul {
        padding: 0 0.5625rem;

        li {
          height: 1.875rem;
          background: #ffffff;
          border-radius: 2px;
          border: 1px solid #dcdfe6;
          display: flex;
          align-items: center;
          padding: 0 0.5rem;

          & + li {
            margin-top: 0.625rem;
          }

          :deep(.el-input) {
            height: 100%;
            font-size: 0.75rem;

            .el-input__wrapper {
              box-shadow: none;

              &:hover {
                box-shadow: none;
              }

              input {
                color: #a8abb2;
              }
            }
          }
        }
      }
    }

    .submit_btn {
      text-align: right;
      padding: 0.625rem 0.5625rem 0;

      .el-button {
        width: 4.375rem;
        height: 1.875rem;
        background: #0095ff;
        border-radius: 2px;
        color: #fff;
        font-size: 0.75rem;
      }
    }
  }
}

.wx-service {
  position: fixed;
  bottom: 390px;
  right: 0.625rem;

  .up {
    width: 1.75rem;
    height: 1.75rem;
    background: #ffffff;
    box-shadow: 0 0 5px 0 rgba(143, 143, 143, 0.6);
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .down {
    position: absolute;
    top: 2.125rem;
    right: 0;
    width: 4.0625rem;
    height: 5.25rem;
    background: #ffffff;
    box-shadow: 0 0 0.3125rem 0 rgba(143, 143, 143, 0.6);
    border-radius: 0.375rem;
    padding: 0.3125rem;

    img {
      width: 100%;
      height: 3.4375rem;
    }

    p {
      font-weight: 400;
      font-size: 0.625rem;
      color: #3d3d3d;
      line-height: 0.875rem;
      text-align: center;
    }
  }
}
</style>
