let timeId = null;
export const debounce = (fn, wait = 100) => {
  return () => {
    if (timeId !== null) {
      clearTimeout(timeId);
    }
    timeId = setTimeout(fn, wait);
  };
};

export const _isMobile = () => {
  // 判断设备是否为移动端的方法
  return /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i.test(
    navigator.userAgent
  );
};
export const _isIos = () => {
  // 判断设备是否为ios
  return !!navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
};
export const _isWeChat = () => {
  // 判断运行环境是否在微信浏览器
  return navigator.userAgent.toLowerCase().match(/MicroMessenger/i) === 'micromessenger';
};
export const _isQQ = () => {
  // 判断运行环境是否在QQ内置浏览器
  return navigator.userAgent.toLowerCase().match(/QQTheme/i) === 'qqtheme';
};

let isEdit = false;
// 防连点引起多次请求
export const throttle = (fn) => {
  if (!isEdit) {
    isEdit = true;
    fn(() => {
      setTimeout(() => {
        isEdit = false;
      }, 1200);
    });
  }
};

export const fetchWithCatch = async (url, options = {}) => {
  try {
    const response = await fetch(url, options);
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    return response.json();
  } catch (error) {
    console.error(`Fetch error: ${error.message}`);
    // 根据需要，可以在这里添加更多的错误处理逻辑，比如用户通知或错误上报
    return null; // 根据实际情况决定是否返回null或其他默认值
  }
};

export function getCurrentDateTime() {
  const now = new Date();
  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, '0');
  const day = String(now.getDate()).padStart(2, '0');
  const hours = String(now.getHours()).padStart(2, '0');
  const minutes = String(now.getMinutes()).padStart(2, '0');
  const seconds = String(now.getSeconds()).padStart(2, '0');

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}
