import axios from '@/utils/axios';

/**
 * 提交联系方式
 * @param {Object} data
 * @param {string} data.name 姓名
 * @param {string} data.phone 手机号
 * @param {string} data.email 邮箱
 * @param {string} data.company 公司名称
 * @param {string} data.demand 需求
 * @returns {Promise}
 */
export const SetConsult_api = async (data) => {
  const res = await axios({
    url: '/webapi/Consult/setConsult',
    method: 'POST',
    data
  });
  return res.data;
};
