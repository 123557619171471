import { useIntersectionObserver } from '@vueuse/core';
// 图片懒加载
const ImgLazyLoading = (app) => {
  app.directive('img-lazy', {
    mounted(el, binding) {
      useIntersectionObserver(el, ([{ isIntersecting }]) => {
        if (isIntersecting) {
          el.src = binding.value;
        }
      });
    }
  });
};

// 导出创建方法
export default function (app) {
  ImgLazyLoading(app);
}
