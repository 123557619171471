import axios from '@/utils/axios';

export const costXfAsrSecond_api = (data) => {
  return axios({
    baseURL: 'https://unityapp.10wei.top',
    url: '/unityweb/ComIflyTekApp/costXfAsrSecond',
    method: 'POST',
    data
  });
};
