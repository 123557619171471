import { createRouter, createWebHistory } from 'vue-router';

const routes = [
  {
    path: '/',
    name: 'layout1',
    component: () => import('@/components/LayOut/LayOutLoad.vue')
  },
  {
    path: '/layout',
    name: 'layout',
    component: () => import('@/components/LayOut/LayOut.vue'),
    children: [
      {
        path: '/home',
        name: 'Home',
        component: () => import('../views/PC/HomeView.vue')
      },
      {
        path: '/Consult',
        name: 'Consult',
        meta: {
          keepAlive: true
        },
        component: () => import('@/views/PC/Consult/Consult.vue')
      },
      {
        path: '/ConsultDetail/:id',
        name: 'ConsultDetail',
        component: () => import('@/views/PC/Consult/ConsultDetail.vue')
      },
      {
        path: '/CoreTechnology',
        name: 'CoreTechnology',
        component: () => import('@/views/PC/CoreTechnology/CoreTechnology.vue')
      },
      {
        path: '/Customized',
        name: 'Customized',
        component: () => import('@/views/PC/Customized/Customized.vue')
      },
      //   产品时空镜
      {
        path: '/Product/skj',
        name: 'ProductSKJ',
        component: () => import('@/views/PC/Product/skj.vue')
      },
      //   产品aihuamn
      {
        path: '/Product/aihuman',
        name: 'ProductAIhuman',
        component: () => import('@/views/PC/Product/aihuman.vue')
      }
    ]
  },
  //   移动端
  {
    path: '/Mobile',
    name: 'Mobile',
    redirect: '/Mobile/MobileHome',
    component: () => import('@/views/Mobile/MobileLayOut.vue'),
    children: [
      {
        path: 'MobileHome',
        name: 'MobileHome',
        component: () => import('@/views/Mobile/MobileHome.vue')
      },
      {
        path: 'MobileConsult',
        name: 'MobileConsult',
        meta: {
          title: '行业资讯'
        },
        component: () => import('@/views/Mobile/MobileConsult/MobileConsult.vue')
      },
      {
        path: 'MobileConsultDetail/:id',
        name: 'MobileConsultDetail',
        meta: {
          isBack: true,
          title: '资讯详情'
        },
        component: () => import('@/views/Mobile/MobileConsult/MobileConsultDetail.vue')
      }
    ]
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

export default router;
