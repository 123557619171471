import axios from 'axios';
import router from '@/router';
import { ElMessage } from 'element-plus';

const tokenKey = 'SHIKONGJIEPAITOKEN';
// 实例
const request = axios.create({
  baseURL: process.env.VUE_APP_URL_PREFIX // url = base url + request url
  //   timeout: 5 * 1000 // request timeout
});

// 请求拦截器
request.interceptors.request.use(
  (config) => {
    // 获取 token值
    const tokenStr = localStorage.getItem(tokenKey);
    // 携带 token
    if (tokenStr) {
      config.headers['token'] = `${tokenStr}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// 响应拦截器
request.interceptors.response.use(
  (response) => {
    let code = response.data.code;

    if ((code === 0 && response.data.info === 'token 已过期！') || response.data.info === '请重新登录，登录认证无效') {
      // 1.清空token
      localStorage.removeItem(tokenKey);
      // 2.跳转登录页
      setTimeout(() => {
        router.push('/').then();
      }, 1000);
      return;
    }

    if (code !== 1 && code !== 2) {
      //   Message({ message: response.data.info, type: 'error', duration: 3000 })
      return Promise.reject(response);
    }

    return response;
  },
  (error) => {
    if (error.response && Number(error.response.status) === 500) {
      //   Message({ message: '服务器错误， 请联系管理员。', type: 'error' })
    } else if (error.message === 'Network Error') {
      //   Message({ message: '网络异常，请检查网络', type: 'error', duration: 3000 })
    }
    return Promise.reject(error);
  }
);

export default function (obj) {
  if (!obj.url) {
    return Promise.reject('缺少业务参数');
  }
  return new Promise((resolve, reject) => {
    let data = {
      ...obj,
      method: obj.method || 'get'
    };
    request(data)
      .then(
        (res) => {
          if (res.data.code === 1) {
            resolve(res.data);
          }
        },
        (err) => {
          ElMessage.error(err.data.info);
          reject(err.data);
        }
      )
      .catch((err) => {
        ElMessage.error(err.data.info);
      });
  });
}
